import { IDocument } from 'types';
import { useResourceList } from '@koopajs/react';

export const getMeetingDocuments = (
  meetingId?: string
): {
  pdfDocuments: IDocument[];
  allDocuments: IDocument[];
} => {
  const { resources: documents } = useResourceList<IDocument>({
    path: `/meetings/${meetingId}/documents`
  });

  return {
    pdfDocuments: documents.filter((doc) => doc.attachedFile.format === 'application/pdf'),
    allDocuments: documents
  };
};
