import { useDownloadDocumentsAsZip } from '../utils/useDownloadDocumentsAsZip';
import { styled, keyframes, Theme } from '@mui/material/styles';
import { Button } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import { IDocument, IMeeting } from 'types';
import { useLocale } from '@koopajs/react';

interface IDownloadAllDocumentsButtonProps {
  documents: IDocument[];
  folderName?: string;
  meeting?: IMeeting;
  meetingDuration?: string;
}

export const DownloadAllDocumentsButton: React.FC<IDownloadAllDocumentsButtonProps> = ({
  documents,
  folderName,
  meeting,
  meetingDuration
}) => {
  // Animated DownloadIcon with gradient effect
  const getGradientAnimation = (theme: Theme) => keyframes`
    0% {
      color: ${theme.palette.primary.main};
    }
    50% {
      color: ${theme.palette.secondary.main};
    }
    100% {
      color: ${theme.palette.primary.main};
    }
  `;

  const AnimatedDownloadIcon = styled(DownloadIcon)(({ theme }) => {
    const gradientAnimation = getGradientAnimation(theme);
    return {
      '&.animating': {
        animation: `${gradientAnimation} 2s infinite linear`
      }
    };
  });

  const { t } = useLocale();
  const { downloadDocumentsAsZip, isDownloading } = useDownloadDocumentsAsZip({
    folderName: folderName ? folderName : t('common:labelDocuments'),
    documents,
    meeting,
    meetingDuration
  });

  const handleZipAndDownloadDocs = async (): Promise<void> => {
    await downloadDocumentsAsZip();
  };
  return (
    <Button
      onClick={handleZipAndDownloadDocs}
      variant="outlined"
      startIcon={isDownloading ? <AnimatedDownloadIcon className="animating" /> : <DownloadIcon />}
      disabled={isDownloading}
      data-cy="meeting-view_download-documents-button"
      sx={{
        position: 'relative',
        '& .MuiButton-startIcon': {
          marginRight: isDownloading ? '10px' : '8px',
          transition: 'all 0.3s ease'
        }
      }}
    >
      {t('common:labelDownloadDocuments')}
    </Button>
  );
};
