import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { IMeeting } from 'types';
import { calculateTopicsStartTime } from './calculateTopicsStartTime';

/**
 * Generates a PDF from the MeetingPrintVersion component that's already rendered in the page
 * @param meeting The meeting data
 * @param meetingDuration The formatted meeting duration string
 * @param t Translation function
 * @param locale Current locale
 * @returns Promise that resolves to a Blob containing the PDF
 */
export const generateAgendaPdf = async (
  meeting: IMeeting,
  meetingDuration: string,
  t: (key: string, options?: any) => string,
  locale: string
): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    try {
      // Find the hidden MeetingPrintVersion component that's already rendered in the page
      const printComponentContainer = document.querySelector('.printWrapper.rr-block');

      if (!printComponentContainer) {
        console.error('Print component not found in the DOM');

        // Fallback to a simple PDF if the component is not found
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: 'a4'
        });

        pdf.setFontSize(16);
        pdf.text(meeting.title || t('common:agenda'), 20, 20);
        pdf.setFontSize(12);
        pdf.text(`${t('time')}: ${meetingDuration}`, 20, 30);

        const pdfBlob = pdf.output('blob');
        resolve(pdfBlob);
        return;
      }

      // Clone the component to avoid modifying the original
      const componentClone = printComponentContainer.cloneNode(true) as HTMLElement;
      componentClone.style.display = 'block';
      componentClone.style.position = 'absolute';
      componentClone.style.left = '-9999px';
      componentClone.style.top = '-9999px';
      componentClone.style.width = '800px';
      componentClone.style.backgroundColor = 'white';
      componentClone.style.padding = '20px';

      // Apply print styles to ensure correct margins and formatting
      componentClone.style.margin = '1in'; // Standard print margin
      componentClone.style.marginTop = '0.7in'; // Match the @page:first style

      // Fix for chip text - replace chips with styled divs that look like chips but render reliably
      const chips = componentClone.querySelectorAll('.MuiChip-root');
      chips.forEach((chip) => {
        const chipElement = chip as HTMLElement;

        // Get the chip text content
        let chipText = '';
        const chipLabel = chipElement.querySelector('.MuiChip-label');
        if (chipLabel) {
          chipText = chipLabel.textContent || '';
        } else {
          chipText = chipElement.textContent || '';
        }

        // Get the chip background color
        const computedStyle = window.getComputedStyle(chipElement);
        const backgroundColor = computedStyle.backgroundColor || '#e0e0e0';

        // Create a replacement div that looks like a chip
        const replacementChip = document.createElement('div');
        replacementChip.textContent = chipText;
        replacementChip.style.display = 'inline-block';

        // Make all chips outlined for consistency
        replacementChip.style.backgroundColor = 'transparent';
        replacementChip.style.border =
          '1px solid ' + (backgroundColor !== 'transparent' ? backgroundColor : '#bdbdbd');

        // Common styles
        replacementChip.style.borderRadius = '16px';
        replacementChip.style.padding = '0 12px';
        replacementChip.style.height = '32px';
        replacementChip.style.lineHeight = '32px';
        replacementChip.style.fontSize = '13px';
        replacementChip.style.fontWeight = '400';
        replacementChip.style.margin = '2px';
        replacementChip.style.color = '#000'; // Black text for outlined chips

        // Ensure text is centered both horizontally and vertically
        replacementChip.style.textAlign = 'center';
        replacementChip.style.display = 'flex';
        replacementChip.style.justifyContent = 'center';
        replacementChip.style.alignItems = 'center';

        // Replace the original chip with our custom div
        if (chipElement.parentNode) {
          chipElement.parentNode.replaceChild(replacementChip, chipElement);
        }
      });

      document.body.appendChild(componentClone);

      // Use setTimeout to ensure the component is fully rendered
      setTimeout(async () => {
        try {
          // Convert the component to canvas with optimal settings for text sharpness
          const canvas = await html2canvas(componentClone, {
            scale: 2, // Higher scale for sharper text
            useCORS: true,
            logging: false,
            backgroundColor: '#ffffff',
            allowTaint: true
          });

          // Create PDF with compression settings
          const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4',
            compress: true
          });

          // Use PNG format for better text quality
          const imgData = canvas.toDataURL('image/png');

          // Calculate dimensions
          const imgWidth = 210; // A4 width in mm
          const pageHeight = 297; // A4 height in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let position = 0;

          // Add image to PDF
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          // Add new pages if the content is longer than one page
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }

          // Set PDF metadata to optimize file size
          pdf.setProperties({
            title: `${meeting.title || t('meeting')} ${t('common:agenda')}`,
            subject: t('common:agenda'),
            creator: 'Panorama'
          });

          // Clean up
          if (componentClone && componentClone.parentNode) {
            componentClone.parentNode.removeChild(componentClone);
          }

          // Convert the PDF to a blob
          const pdfBlob = pdf.output('blob');
          resolve(pdfBlob);
        } catch (error) {
          console.error('Error generating PDF:', error);

          // Clean up on error
          if (componentClone && componentClone.parentNode) {
            componentClone.parentNode.removeChild(componentClone);
          }

          // Provide a fallback simple PDF
          const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4'
          });

          pdf.setFontSize(16);
          pdf.text(`${t('common:agenda')} (${t('labelBack')})`, 20, 20);
          pdf.setFontSize(12);
          pdf.text(`${t('meeting')}: ${meeting.title || t('title')}`, 20, 30);
          pdf.text(`${t('time')}: ${meetingDuration}`, 20, 40);

          const pdfBlob = pdf.output('blob');
          resolve(pdfBlob);
        }
      }, 500); // Give it 500ms to render
    } catch (error) {
      console.error('Error setting up PDF generation:', error);

      // Provide a fallback simple PDF
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });

      pdf.setFontSize(16);
      pdf.text(`${t('common:agenda')} (${t('labelBack')})`, 20, 20);
      pdf.setFontSize(12);
      pdf.text(`${t('meeting')}: ${meeting.title || t('title')}`, 20, 30);

      const pdfBlob = pdf.output('blob');
      resolve(pdfBlob);
    }
  });
};
