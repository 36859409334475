import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useLocale, useParamsKey, useResourceShow, useUserShow } from '@koopajs/react';
import { Box, Typography, Stack, Button, Divider, Chip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { MeetingStateChip } from 'components/Meetings/MeetingStateChip';
import { PageContainer } from 'components/temp/PageContainer';
import { IMeeting } from 'types';
import { MeetingViewHeaderCard } from 'components/Meetings/View/MeetingViewHeaderCard';
import { MeetingViewTopicCard } from 'components/Meetings/View/MeetingViewTopicCard';
import { TimerOutlined as TimerOutlinedIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { RenderPrintButton } from 'components/RenderPrintButton';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';
import puzzleImage from 'assets/people-completing-puzzle.png';
import { calculateTopicsStartTime } from 'utils/calculateTopicsStartTime';
import { StartMeetingBanner } from 'components/Meetings/StartMeetingBanner';
import { MeetingPrintVersion } from '../components/Meetings/MeetingPrintVersion';
import { MeetingLoadingOrError } from 'components/Meetings/MeetingLoadingOrError';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { EventsTimeline } from 'components/EventsTimeline';
import { generateMeetingTimeline } from 'utils/generateMeetingTimeline';
import { getMeetingDocuments } from 'utils/getMeetingDocuments';
import GestureIcon from '@mui/icons-material/Gesture';
import { PanoramaDocumentWrapper } from 'components/PanoramaDocumentWrapper';
import { DownloadAllDocumentsButton } from 'components/DownloadAllDocumentsButton';
import { useFeatureGate } from '@statsig/react-bindings';

export const MeetingView: React.FC = () => {
  const meetingId = useParamsKey('meetingId');

  const printComponentRef = useRef(null);

  const keyPrefix = 'MeetingView';

  const { t } = useLocale();

  const {
    resource: meeting,
    isProcessing,
    errorMessage
  } = useResourceShow<IMeeting>({ path: '/meetings', id: meetingId });

  const { user } = useUserShow();

  const {
    hasMeetingStarted,
    meetingDuration,
    isUserMeetingCreatorRole,
    isMeetingAgendaSent,
    canUserCurrentlyEditMeeting,
    isUserAuthorizedEditor,
    isUserMeetingNoteTakerRole
  } = useMeetingStatus({
    meeting,
    user
  });

  const { pdfDocuments: meetingPdfDocuments, allDocuments } = getMeetingDocuments(meeting?.id);
  const { value: isBlockingDocumentsDownload } = useFeatureGate('block_document_downloads');

  const userHasEditAccess = Boolean(isUserMeetingCreatorRole && !hasMeetingStarted);

  const isAgendaVisible = isMeetingAgendaSent || userHasEditAccess || hasMeetingStarted;

  const topics = meeting && calculateTopicsStartTime(meeting);

  const timelineEvents = generateMeetingTimeline(meeting);

  // const canUserDownloadAllDocuments = Boolean(
  //   isAgendaVisible && allDocuments.length && allDocuments.length > 0 && !isBlockingDocumentsDownload
  // );

  const canUserDownloadAllDocuments = !isBlockingDocumentsDownload;

  if (!meeting) {
    return <MeetingLoadingOrError isProcessing={isProcessing} errorMessage={errorMessage} />;
  }

  return (
    <>
      <Helmet>
        <title>{`${t('common:viewMeeting')} - Panorama`}</title>
      </Helmet>
      {isAgendaVisible && (
        <Box sx={{ display: 'none' }}>
          <MeetingPrintVersion
            meeting={{ ...meeting, topics }}
            ref={printComponentRef}
            meetingDuration={meetingDuration}
          />
        </Box>
      )}
      <PageContainer
        sxChildren={{
          padding: {
            xs: 3,
            md: '24px 56px'
          }
        }}
        className="rr-block"
        testId="meeting-view-page"
      >
        <Breadcrumbs
          hierarchyArray={[{ path: '/', text: t('common:navigation.upcomingMeetings') }]}
          pageTitle={meeting?.title || ''}
          sx={{
            display: { xs: 'none', sm: 'block' }
          }}
        />
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
            sx={{ mb: 3, mt: { xs: 0, sm: 3 } }}
            gap={{ xs: '14px', md: 3 }}
          >
            <Button
              component={RouterLink}
              to="/"
              variant="outlined"
              sx={{ minWidth: 0, px: '5px', display: { sm: 'none' } }}
              aria-label={t('common:labelBack')}
            >
              <ArrowBackIcon sx={{ color: 'primary.main' }} />
            </Button>
            <Typography
              data-cy="meeting-view_title"
              variant="h1"
              sx={{
                display: 'inline-block',
                fontWeight: 400,
                fontSize: '24px',
                mr: meeting.minutesStartedAt ? 'none' : 'auto',
                minWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {meeting?.title}
            </Typography>

            {/* STATE CHIP */}
            {meeting.minutesStartedAt && (
              <MeetingStateChip meeting={meeting} sx={{ ml: { xs: 'auto', sm: 0 } }} />
            )}

            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                ml: 'auto'
              }}
            >
              {isMeetingAgendaSent && (
                <Button
                  component={RouterLink}
                  startIcon={<GestureIcon />}
                  to={`/meeting-annotate/${meeting.id}`}
                  variant={
                    isUserMeetingCreatorRole && !hasMeetingStarted
                      ? 'text'
                      : isUserAuthorizedEditor || isUserMeetingNoteTakerRole
                      ? 'outlined'
                      : 'contained'
                  }
                  data-cy="meeting-view_annotate-button"
                >
                  {t('common:labelAnnotate')}
                </Button>
              )}
              {canUserCurrentlyEditMeeting && (
                <Button
                  variant="outlined"
                  component={RouterLink}
                  to={{
                    pathname: `/meetings/${meeting.id}/edit`,
                    state: { activePath: '/upcoming-meetings' }
                  }}
                  sx={{ ml: 'auto' }}
                >
                  {t('common:labelEdit')}
                </Button>
              )}
            </Box>
          </Stack>
        </Box>

        <StartMeetingBanner meeting={meeting} sx={{ mb: 3 }} />

        {/* MEETING DETAILS */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <PanoramaDocumentWrapper>
            {/* MEETING DETAILS */}
            {meeting && <MeetingViewHeaderCard meeting={meeting} />}

            <Divider sx={{ my: '14px' }} />

            {/* TOPICS */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} data-cy="meeting-view_topics">
              {isAgendaVisible ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3
                    }}
                  >
                    <Typography variant="overline">{t(keyPrefix + '.agendaLabel')}</Typography>
                    {!hasMeetingStarted && isUserMeetingCreatorRole && (
                      <Chip
                        label={
                          isMeetingAgendaSent
                            ? t(keyPrefix + '.chipAgendaSent')
                            : t(keyPrefix + '.chipAgendaInPreparation')
                        }
                        sx={{ backgroundColor: isMeetingAgendaSent ? 'greenTwo.60' : 'customGrey.main' }}
                        data-cy="meeting-view_agenda_state-chip"
                      />
                    )}
                  </Box>

                  {topics?.map((topic, index) => {
                    return (
                      <MeetingViewTopicCard
                        key={topic.id}
                        topic={topic}
                        order={index + 1}
                        meetingPdfDocuments={meetingPdfDocuments}
                        areDocumentsAnnotatable
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                      px: '30px',
                      textAlign: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <Typography variant="h6" component="p" data-cy="meeting-view_agenda-in-preparation-text">
                      {t(keyPrefix + '.agendaInPreparation')}
                    </Typography>
                    <Box
                      component="img"
                      loading="lazy"
                      sx={{
                        maxWidth: '482px',
                        width: '100%'
                      }}
                      alt=""
                      src={puzzleImage}
                    />
                  </Box>
                </>
              )}
            </Box>

            {isAgendaVisible && (
              <>
                <Divider sx={{ my: '14px' }} />

                {/* DURATION */}
                <Stack direction="row" justifyContent="flex-end">
                  <Typography variant="body1" sx={{ display: 'flex' }}>
                    <TimerOutlinedIcon color="action" sx={{ mr: 1 }} />
                    {t(keyPrefix + '.estimatedDurationLabel', { time: meetingDuration })}
                  </Typography>
                </Stack>
              </>
            )}
          </PanoramaDocumentWrapper>
        </Box>

        {/* FOOTER */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: 2,
            mt: 3
          }}
        >
          {isAgendaVisible && (
            <Box sx={{ width: '100%' }}>
              <EventsTimeline timelineEvents={timelineEvents} />
            </Box>
          )}

          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'space-between', sm: 'flex-end' },
                gap: { sm: 2 }
              }}
            >
              {/* desktop back */}
              <Button component={RouterLink} to="/" sx={{ display: { xs: 'none', sm: 'inline-flex' } }}>
                {t('common:labelBack')}
              </Button>
              {/* mobile back */}
              <Button
                component={RouterLink}
                to="/"
                variant="outlined"
                sx={{ minWidth: 0, px: '5px', display: { sm: 'none' } }}
                aria-label={t('common:labelBack')}
              >
                <ArrowBackIcon sx={{ color: 'primary.main' }} />
              </Button>

              {isAgendaVisible && <RenderPrintButton componentRef={printComponentRef} variant="outlined" />}

              {canUserDownloadAllDocuments && (
                <DownloadAllDocumentsButton
                  documents={allDocuments}
                  folderName={`${meeting?.title?.replace(/[^a-z0-9]/gi, '_')} documents`}
                  meeting={meeting}
                  meetingDuration={meetingDuration}
                />
              )}
            </Box>
          </Box>
        </Box>
      </PageContainer>
    </>
  );
};
