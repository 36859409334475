import { saveAs } from 'file-saver';
import { IDocument, IMeeting } from 'types';
import JSZip from 'jszip';
import { useState, useCallback } from 'react';
import { Api } from '@koopajs/app';
import { useLocale } from '@koopajs/react';
import { generateAgendaPdf } from './generateAgendaPdf';

interface IDownloadDocumentsAsZipProps {
  folderName: string;
  documents: IDocument[];
  meeting?: IMeeting;
  meetingDuration?: string;
}

export const useDownloadDocumentsAsZip = (
  props: IDownloadDocumentsAsZipProps
): { downloadDocumentsAsZip: () => Promise<void>; isDownloading: boolean } => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { t, locale } = useLocale();

  const downloadDocumentsAsZip = useCallback(async () => {
    const { folderName, documents, meeting, meetingDuration } = props;

    // if (!documents || documents.length === 0) {
    //   return;
    // }

    setIsDownloading(true);

    try {
      const zip = new JSZip();
      const folder = zip.folder(folderName);

      if (!folder) {
        throw new Error('Failed to create zip folder');
      }

      // Create an array of promises for downloading each document concurrently
      const downloadPromises = documents.map(async (doc) => {
        try {
          const url = `/documents/${doc.id}`;
          const documentResponse = await Api.client.get(url);
          const downloadUrl = documentResponse.data.resource.attachedFileUriDownload;

          const response = await fetch(downloadUrl);
          if (!response.ok) {
            throw new Error(`Failed to download file: Status ${response.status}`);
          }

          const blob = await response.blob();

          const fileName = doc.attachedFile.name || `document_${doc.id}.pdf`;
          folder.file(fileName, blob);
        } catch (error) {
          console.error(`Error processing document ${doc.id}:`, error);
        }
      });

      // Generate and add the agenda PDF if meeting data is provided
      if (meeting && meetingDuration) {
        try {
          const agendaPdfBlob = await generateAgendaPdf(meeting, meetingDuration, t, locale);
          folder.file(`${t('common:agenda')}.pdf`, agendaPdfBlob);
        } catch (error) {
          console.error('Error generating agenda PDF:', error);
        }
      }

      await Promise.all(downloadPromises);

      const zipBlob = await zip.generateAsync({ type: 'blob' });

      // Trigger the download
      saveAs(zipBlob, `${folderName}.zip`);
    } catch (error) {
      console.error('Error creating zip file:', error);
    } finally {
      setIsDownloading(false);
    }
  }, [props]);

  return { downloadDocumentsAsZip, isDownloading };
};
